import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import tw, { styled } from "twin.macro"
import Button from "@elements/Button"
import MovingElement from "@elements/MovingElement"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Section = styled.section`
  ${tw`relative`}
`
const Teaser = styled.div`
  ${tw`relative pb-c80 md:pb-c64 2xl:pb-c120`}
`
const Head = styled.div`
  ${tw`relative grid grid-cols-12 mb-c40 md:mb-c32 z-10`}
`
const Content = styled.div`
  ${tw`relative font-ubuntu font-bold`}
  & .hp-button {
    ${tw`pt-c20`}
  }
`
const Symbol = styled.p`
  ${tw`font-firacode whitespace-nowrap text-6xl text-center font-black text-yellow absolute transform top-0 left-0 -translate-x-full transition-all duration-300 ease-linear`}
`
const Headline = styled.h2`
  ${tw`relative mb-c20`}
`
const Subline = styled.p`
  ${tw`relative text-18 2xl:text-20`}
`

const Gallery = styled.div`
  ${tw`relative grid gap-4 md:gap-8 grid-flow-col auto-cols-auto justify-center`}
  & > * {
    ${tw`flex`}
  }
`

const WpAcfReferenzenArchiveBlock = () => {
  const data = useStaticQuery(graphql`
    {
      allWpReferenz(sort: { fields: date, order: DESC }) {
        edges {
          node {
            uri
            slug
            acfReferezenTeasers {
              archiveTeaser {
                symbol
                headline
                subline
                gallery {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 50
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Section>
      {data.allWpReferenz.edges.map(({ node }, index) => {
        const {
          symbol,
          headline,
          subline,
          gallery,
        } = node.acfReferezenTeasers.archiveTeaser
        return (
          <Teaser key={node.slug}>
            <Head
              className={
                index % 2 !== 0
                  ? "px-offset md:pl-0 md:pr-16 xl:pr-24"
                  : "px-offset sm:px-12 lg:px-16 xl:px-24 xl:max-w-[1000px] mx-auto"
              }
            >
              <Content
                className={
                  index % 2 === 0
                    ? "col-span-12"
                    : "col-span-12 md:col-span-6 md:col-start-7"
                }
              >
                <MovingElement
                  speed={0.02}
                  direction={{
                    negative: "+",
                    axis: "y",
                  }}
                  content={false}
                  className="transition-all duration-1000 ease-in-out mt-auto"
                >
                  <Symbol
                    style={{
                      writingMode: "vertical-lr",
                      lineHeight: "1.6",
                    }}
                  >
                    {symbol ?? "=>>=>>"}
                  </Symbol>
                </MovingElement>
                <Headline className="headline-card">{headline}</Headline>
                <Subline>{subline}</Subline>
                <Button
                  buttonText="Zum case"
                  buttonHref={node.uri}
                  buttonClasses="bg-yellow text-black mr-auto"
                />
              </Content>
            </Head>
            <Gallery>
              {gallery.map((item, i) => {
                const image = getImage(item.localFile)
                return (
                  <MovingElement
                    key={`archive-img-${i}`}
                    speed={i % 2 !== 0 ? 0.02 : 0.03}
                    direction={{
                      negative: i % 2 !== 0 ? "-" : "+",
                      axis: "y",
                    }}
                    content={false}
                    className="transition-all duration-1000 ease-in-out mt-auto"
                  >
                    <GatsbyImage
                      className="mt-auto"
                      objectFit="contain"
                      image={image}
                      alt=""
                    />
                  </MovingElement>
                )
              })}
            </Gallery>
          </Teaser>
        )
      })}
    </Section>
  )
}

export default WpAcfReferenzenArchiveBlock
